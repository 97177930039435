import React from 'react';

import Layout from '../components/Layout';
import {MicroCard, TabTitleCard} from '../components/Card';



import CareerImage from '../assets/images/icon-career.svg';


import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import RightImage from '../assets/images/icon-right.svg';

import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import CloudImage from '../assets/images/icon-cloudenable.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import TransformImage from '../assets/images/icon-transform.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';

const herobg = '#1c2841', herofcolor='#efefef';
const CodePage = () => (
  <Layout>
      <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>                        
                        <h1 style={{letterSpacing:'.1em'}}>We believe, a thoughtful & committed team 
                            can change the way, the world operates.</h1>
                </header>
        </div>
    
        
        <div className="inner row" >
            <>
                <TabTitleCard className='col-3' 
                            cardTitle="Career" selected={true}/>
                <TabTitleCard className='col-3' 
                        cardTitle="Current openings" 
                        cardLink='/current-jobs'/>
            </>
            <div className='col-9 col-12-medium' style={{margin:'.25em auto'}}>
                <span className="image left">
                    <img src={CareerImage} alt="Careers" style={{width:'120px'}} />
                </span>
            
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    Cloutics delivers digital transformation and technology services. Attracting and 
                    developing the best talent is always an important area of focus for Cloutics, 
                    as we believe that our people are not only our greatest asset but also our biggest 
                    competitive advantage.
                    
                </p> 
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    Career & Development
                </p>
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    At Cloutics, we believe that leadership exists at all levels and we work towards 
                    developing employees on technical skills, people skills and strategy. Each employee at Cloutics 
                    will not only become an expert in their field of specialization but also build their 
                    inter-personal and managerial skills. This combination provides natural progression in your career 
                    and helps our clients with a distinct advantage 
                </p>
           
            
                <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'.25em auto'}}>
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Upgrade skills" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Delivery focussed" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Best practices in the industry" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Support network of colleagues & mentors" />
                    
                </div>
                <br /> <br />
            
                
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    Benefits
                </p>
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    Our success is a direct result of our ability to attract and retain the finest minds from the industry. 
                    We take care of our colleagues with competitive pay and benefits balanced with personal development 
                    and networking opportunities. Our clients choose us time and again because they have learned to 
                    expect high performance and outstanding results.
                    <br/><br />
                    
                </p>

                <div className="inner row" style={{display:'flex', flexWrap:'wrap', margin:'.25em auto'}}>
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Competitive Pay" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Health & Dental Insurance" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Flexible spending accounts" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Worker's compensation policy" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Health savings accounts" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Vacation & Leave Policy" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Relocation expense claim" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Sponsoring visa to US/Canada" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="PR to US/Canada" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Remote Work Option" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Referral policy" />
                    <MicroCard className='col-6 col-12-small' cardImage={RightImage} cardTitle="Incentives for performance" />
                    
                    
                    
                </div>
            </div>
        
            <div className='col-3 col-6-medium col-12-small' >
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>
                    Know about what we do
                </p>
                <MicroCard className='col-12 col-6-medium' 
                            cardTitle="Transformation" cardImage={TransformImage} 
                            cardLink='/transform-your-business'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Solutions on the cloud" cardImage={SolutionsImage} 
                        cardLink='/business-solutions-on-the-cloud'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                    cardLink='/public-sector-services'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Talent Services" cardImage={TalentImage} 
                        cardLink='/talent-services'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                        cardLink='/lowcode-nocode'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Modernize" cardImage={ModernizeImage} 
                    cardLink='/modernize-solutions'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Enable the Cloud" cardImage={CloudImage} 
                        cardLink='/enable-the-cloud'/>
                    
                    
            </div>
        </div>
    </div>
  </Layout>
);

export default CodePage;
